<template>
  <div style='height: 100%'>
    <div class='serviceSupervision'>
      <div class='serviceSupervision_left'>
        <RemoteInspectionLeft
          :refresh-key='refreshKey'
          style='margin-bottom: 29px'
        ></RemoteInspectionLeft>
        <EarlyWarningHistoryLeft
          :prefix='prefix'
          :refresh-key='refreshKey'
        ></EarlyWarningHistoryLeft>
      </div>
      <div class='serviceSupervision_center'>
        <div class='img_center'>
          <FloatBox>
            <template slot='one-line'>
              <template v-for='code of [4,5]'>
                <template v-if='list[code]'>
                  <div
                    v-for='(e, i) in [list[code]]'
                    :key='i + code'
                    class='line-box'
                    style='cursor: pointer'
                  >
                    <span>{{ e.text }}：</span>
                    <span class="cyan-value" @click="numberClick(e.code,1)">{{
                        formatString(e.totalCount)
                      }}</span>
                    &nbsp;/&nbsp;
                    <span class='red-value' @click="numberClick(e.code)">{{ formatString(e.count) }}</span>
                  </div>
                </template>
              </template>
            </template>
            <template slot='two-line'>
              <template v-for='code of [2,3]'>
                <template v-if='list[code]'>
                  <div
                    v-for='(e, i) in [list[code]]'
                    :key='i + code'
                    class='line-box'
                    style='cursor: pointer'
                  >
                    <span>{{ e.text }}：</span>
                    <span class="cyan-value" @click="numberClick(e.code,1)">{{
                        formatString(e.totalCount)
                      }}</span>
                    &nbsp;/&nbsp;
                    <span class='red-value' @click="numberClick(e.code)">{{ formatString(e.count) }}</span>

                  </div>
                </template>
              </template>
            </template>
            <template slot='three-line'>
              <template v-for='code of [0,1]'>
                <template v-if='list[code]'>
                  <div
                    v-for='(e, i) in [list[code]]'
                    :key='i + code'
                    class='line-box'
                    style='cursor: pointer'

                  >
                    <span>{{ e.text }}：</span>
                    <span class="cyan-value" @click="numberClick(e.code,1)">{{
                        formatString(e.totalCount)
                      }}</span>
                    &nbsp;/&nbsp;
                    <span class='red-value' @click="numberClick(e.code)">{{ formatString(e.count) }}</span>
                  </div>
                </template>
              </template>
            </template>
          </FloatBox>
        </div>
        <EarlyWarningTrendFooter
          :global-query='globalQuery'
          :prefix='prefix'
          :refresh-key='refreshKey'
        ></EarlyWarningTrendFooter>
      </div>
      <div class='serviceSupervision_right'>
        <EarlyWarningHandleRight
          :global-query='globalQuery'
          :prefix='prefix'
          :refresh-key='refreshKey'
          @ok="getMonitordata"
          type='serviceSupervision'
        ></EarlyWarningHandleRight>
      </div>
    </div>
    <MorningTableDialog ref='MorningTableDialog'></MorningTableDialog>
    <ViolationTableDialog ref='ViolationTableDialog'></ViolationTableDialog>
    <EarlyWarningTableDialog
      ref='EarlyWarningTableDialog'
      :analysis-item-list='serveAnalysisItemList'
      :prefix='prefix'
    ></EarlyWarningTableDialog>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import {formatString} from '@/views/screen/config';
import MorningTableDialog from './modules/MorningTableDialog.vue';
import ViolationTableDialog from './modules/ViolationTableDialog.vue';
import EarlyWarningTableDialog from '@/views/screen/earlyWarning/modules/EarlyWarningTableDialog.vue';

export default {
  //组件注册
  components: {
    RemoteInspectionLeft: () => import('./modules/RemoteInspectionLeft.vue'),
    EarlyWarningHistoryLeft: () =>
      import(
        '@/views/screen/cashSupervision/modules/EarlyWarningHistoryLeft.vue'
        ),
    FloatBox: () =>
      import('@/views/screen/cashSupervision/components/FloatBox.vue'),
    EarlyWarningTrendFooter: () =>
      import(
        '@/views/screen/cashSupervision/modules/EarlyWarningTrendFooter.vue'
        ),
    EarlyWarningHandleRight: () =>
      import(
        '@/views/screen/cashSupervision/modules/EarlyWarningHandleRight.vue'
        ),
    MorningTableDialog,
    ViolationTableDialog,
    EarlyWarningTableDialog,
  },
  //组件传值
  props: {
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      prefix: 'serve',
      num: 0,
      list: [],
      timer: null,
    };
  },
  //计算属性
  computed: {
    ...mapState({
      globalQuery: (state) => state.screenStore.globalQuery,
      warningTotal: (state) => state.screenStore.warningTotal,
      serveAnalysisItemList: (state) =>
        state.screenStore.analysisitem['serveAnalysisItemList']
          ? state.screenStore.analysisitem['serveAnalysisItemList']
          : [],
    }),


  },
  //属性监听
  watch: {
    globalQuery: {
      deep: true,
      handler: 'getMonitordata',
    },
    refreshKey: {
      handler: 'getMonitordata',
    },
  },
  //DOM访问
  mounted() {
    this.$bus.$on('MorningTableDialog', (options) => {
      this.$refs.MorningTableDialog &&
      this.$refs.MorningTableDialog.openDialog(options);
    });
    this.$bus.$on('EarlyWarningTableDialog', (options) => {
      this.$refs.EarlyWarningTableDialog &&
      this.$refs.EarlyWarningTableDialog.openDialog(options);
    });
    this.$bus.$on('ViolationTableDialog', (options) => {
      this.$refs.ViolationTableDialog &&
      this.$refs.ViolationTableDialog.openDialog(options);
      console.log(options);
    });
    this.$bus.$on(`warning-success-${this.prefix}`, () => this.getMonitordata);
    this.$bus.$on('global-refresh', () => {
      this.$bus.$emit(`warning-success-${this.prefix}`);
    });
    if (Object.keys(this.globalQuery).length) {
      clearInterval(this.timer)
      this.getMonitordata();
    }
  },
  //保存方法
  methods: {
    numberClick(code, itemUuid) {
      const fixedParam = {
        searchViolationItemCodeList: code,
        // searchAnalysisItems: itemUuid || ''
      }
      if (!itemUuid) {
        // fixedParam.handleFlag = '02'
        fixedParam.violationFlag = '02'
      } else {
        // fixedParam.handleFlag = ''
        fixedParam.violationFlag = ''
      }
      this.$bus.$emit('ViolationTableDialog', {
        fixedParam
      })
    },
    formatString,
    ...mapMutations(['screenStore/SET_warningTotal']),
    sliceList(down) {
      let length = down.length / 6;
      let arr = [];
      length = Math.floor(length);
      length = down.length % 6 === 0 ? length : length + 1;
      for (let i = 0; i < length; i++) {
        arr.push(down.slice(6 * i, 6 * i + 6));
      }
      return arr;
    },
    getWarningTotal(index) {
      if (this.list[index]) {
        return this.list[index];
      } else {
        return {};
      }

    },
    async getMonitordata() {
      clearInterval(this.timer)
      this.list = []
      // this['screenStore/SET_warningTotal']({});
      this.timer = null
      const data = await this.$http.get(
        `/${this.$businessISG}/monitor/${this.prefix}/violation-stat`,
        this.globalQuery,
      );
      if (data.code == 200 && data.data) {
        // const list = data.data[0].down;
        const list = this.sliceList(data.data[0].down);
        this.list = list[this.num];
        this['screenStore/SET_warningTotal'](data.data[0]);
        this.timer = setInterval(() => {
          this.num = this.num === list.length - 1 ? 0 : this.num + 1;
          this.list = list[this.num];
        }, 10000);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang='scss' scoped>
.serviceSupervision {
  display: flex;
  height: 100%;

  .serviceSupervision_left,
  .serviceSupervision_right {
    flex: 0 0 26%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .layoutBox {
      flex: 1;
      overflow: hidden;
    }
  }

  .serviceSupervision_center {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 38px;
    overflow: hidden;

    .img_center {
      flex: 1;
      overflow: hidden;
      background-image: url('../../../assets/screen/bj/serviceSupervision_center.gif');
      background-position: bottom;
    }

    .layoutBox {
      flex: 0 0 245px;
      overflow: hidden;
    }
  }
}
::v-deep .float-box {
  .one, .two {
    .line-box:nth-child(2)  {
      margin-left: 30px;
    }
  }
}
</style>
