<template>
  <Dialog v-if="visible" v-model="visible" :options="dialogOptions.dialog">
    <TableLayout
      :columns="columns"
      :query-format="queryFormat"
      :format-global-query="formatGlobalQuery"
      :url="`/${$businessISG}/monitor/serve/meeting-page`"
    >
      <template slot="query-form" slot-scope="{ queryParam }">
        <QueryLayout lable="机构名称">
          <SelectTree
            v-model="queryParam.organizationUUID"
            :options="organizationTree"
            :replace-fields="{
              itemText: 'name',
              itemKey: 'uuid',
              itemChildren: 'children',
            }"
            placeholder="请选择机构名称"
          ></SelectTree>
        </QueryLayout>
        <QueryLayout lable="晨会状态">
          <Select
            v-model="queryParam.meetingStatus"
            :options="meetingStatus"
            :replace-fields="{
              lable: 'msg',
              value: 'code',
            }"
            placeholder="请选择晨会状态"
          ></Select>
        </QueryLayout>
        <QueryLayout lable="时间">
          <DatePicker v-model="queryParam.planCreateTime" range></DatePicker>
        </QueryLayout>
      </template>
      <div
        slot="table-item-action"
        slot-scope="{ row }"
        style="cursor: pointer"
      >
        <img
          src="@/assets/screen/bj/icon_video.png"
          alt="icon_video"
          @click="
            row.attach || (row.imageList && row.imageList.length)
              ? $bus.$emit('CameraDialog', {
                  videoUrl: `${$imgUrl}${row.attach}`,
                  imageList: row.imageList,
                  attachList: row.attachList,
                })
              : null
          "
        />
      </div>
    </TableLayout>
  </Dialog>
</template>

<script>
import { mapState } from 'vuex';
import { assiginDeep } from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';
import TableLayout from '@/views/screen/components/TableLayout.vue';
import QueryLayout from '@/views/screen/components/QueryLayout.vue';
import Select from '@/views/screen/components/Select.vue';
import SelectTree from '@/views/screen/components/SelectTree.vue';
import DatePicker from '@/views/screen/components/DatePicker.vue';
export default {
  //组件注册
  components: {
    Dialog,
    TableLayout,
    QueryLayout,
    Select,
    SelectTree,
    DatePicker,
  },
  //组件传值
  props: {},
  data() {
    return {
      visible: false,
      dialogOptions: {
        dialog: {
          width: 1500,
          title: '晨会记录',
        },
      },
      columns: [
        {
          text: '序号',
          value: 'sortNo',
          sortable: false,
          align: 'center',
        },
        {
          text: '机构名称',
          value: 'organizationText',
          sortable: false,
          align: 'center',
        },
        {
          text: '日期',
          value: 'meetingDate',
          sortable: false,
          align: 'center',
        },
        {
          text: '晨会状态',
          value: 'meetingStatusText',
          sortable: false,
          align: 'center',
        },
        {
          text: '开始时间',
          value: 'meetingStartTime',
          sortable: false,
          align: 'center',
        },
        {
          text: '结束时间',
          value: 'meetingEndTime',
          sortable: false,
          align: 'center',
        },
        {
          text: '操作',
          value: 'action',
          sortable: false,
          align: 'center',
          itemSlots: true,
        },
      ],
    };
  },
  //计算属性
  computed: {
    ...mapState({
      organizationTree: (state) => state.screenStore.organizationTree,
      meetingStatus: (state) =>
        state.screenStore.commondict['MonitorMeetingStatusEnum']
          ? state.screenStore.commondict['MonitorMeetingStatusEnum']
          : [],
    }),
  },
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    formatGlobalQuery(globalQuery) {
      const query = {
        organizationUUID: [globalQuery.organizationUUID],
      };
      if (globalQuery.timeStartStr && globalQuery.timeEndStr) {
        query.planCreateTime = ['timeStartStr', 'timeEndStr'].map(
          (key) => globalQuery[key]
        );
      }
      return query;
    },
    queryFormat(query) {
      for (const key in query) {
        if (['organizationUUID'].includes(key) && Array.isArray(query[key])) {
          query[key] = query[key].toString();
        } else if (
          ['planCreateTime'].includes(key) &&
          Array.isArray(query[key]) &&
          query[key].length == 2
        ) {
          Object.assign(
            query,
            ['StartStr', 'EndStr'].reduce(
              (val, suffix, i) => ({
                ...val,
                [`${key}${suffix}`]: query[key][i] ?? '',
              }),
              {}
            )
          );
          delete query[key];
        }
      }
      if (this.dialogOptions.query)
        query = Object.assign(query, this.dialogOptions.query);
      return query;
    },
    openDialog(options = {}) {
      this.dialogOptions = assiginDeep(this.dialogOptions, options);
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
