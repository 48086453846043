var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('Dialog',{attrs:{"options":_vm.dialogOptions.dialog},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('TableLayout',{attrs:{"columns":_vm.columns,"query-format":_vm.queryFormat,"format-global-query":_vm.formatGlobalQuery,"url":("/" + _vm.$businessISG + "/monitor/serve/meeting-page")},scopedSlots:_vm._u([{key:"query-form",fn:function(ref){
var queryParam = ref.queryParam;
return [_c('QueryLayout',{attrs:{"lable":"机构名称"}},[_c('SelectTree',{attrs:{"options":_vm.organizationTree,"replace-fields":{
            itemText: 'name',
            itemKey: 'uuid',
            itemChildren: 'children',
          },"placeholder":"请选择机构名称"},model:{value:(queryParam.organizationUUID),callback:function ($$v) {_vm.$set(queryParam, "organizationUUID", $$v)},expression:"queryParam.organizationUUID"}})],1),_c('QueryLayout',{attrs:{"lable":"晨会状态"}},[_c('Select',{attrs:{"options":_vm.meetingStatus,"replace-fields":{
            lable: 'msg',
            value: 'code',
          },"placeholder":"请选择晨会状态"},model:{value:(queryParam.meetingStatus),callback:function ($$v) {_vm.$set(queryParam, "meetingStatus", $$v)},expression:"queryParam.meetingStatus"}})],1),_c('QueryLayout',{attrs:{"lable":"时间"}},[_c('DatePicker',{attrs:{"range":""},model:{value:(queryParam.planCreateTime),callback:function ($$v) {_vm.$set(queryParam, "planCreateTime", $$v)},expression:"queryParam.planCreateTime"}})],1)]}},{key:"table-item-action",fn:function(ref){
          var row = ref.row;
return _c('div',{staticStyle:{"cursor":"pointer"}},[_c('img',{attrs:{"src":require("@/assets/screen/bj/icon_video.png"),"alt":"icon_video"},on:{"click":function($event){row.attach || (row.imageList && row.imageList.length)
            ? _vm.$bus.$emit('CameraDialog', {
                videoUrl: ("" + _vm.$imgUrl + (row.attach)),
                imageList: row.imageList,
                attachList: row.attachList,
              })
            : null}}})])}}],null,false,4184635451)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }